.bestseller-carousel {
  position: relative;
}

.bestseller-carousel__items {
  display: flex;
  overflow-x: hidden;
  gap: 1rem;
  justify-content: center;
}

.bestseller-carousel__item {
  flex: 0 0 auto;
  width: 22%;
  text-align: left;
  padding: 0.7rem 0.7rem 1rem;
  border-radius: 5px;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: var(--container-color);
}

.bestseller-carousel__item:hover {
  box-shadow: 0 20px 20px -10px rgba(0, 0, 0, 0.2);
}

.bestseller-carousel__item img {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 10px;
}

.bestseller-carousel__title {
  font-weight: 700;
  font-size: 15px;
  line-height: 1.2rem;
  margin-bottom: 5px;
  min-height: 60px;
}

.bestseller-carousel__text {
  font-size: 13px;
  margin-bottom: 10px;
  line-height: 1.2rem;
  min-height: 100px;
}

.bestseller-carousel__inner {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1rem;
}

.bestseller-carousel__id {
  font-weight: 700;
  font-size: 14px;
}

.bestseller-carousel__button {
  color: white;
}

.bestseller-carousel__nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 18px;
  cursor: pointer;
}

.bestseller-carousel__nav--prev {
  left: 0rem;
}

.bestseller-carousel__nav--next {
  right: 0rem;
}

.bestseller-carousel__sale {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: red;
  color: white;
  padding: 20px 15px;
  border-radius: 50%;
}

.bestseller-carousel__prcies {
  display: flex;
  gap: 0.5rem;
  align-items: baseline;
}

.bestseller-carousel__salep {
  font-size: 19px;
  font-weight: 800;
}

.bestseller-carousel__price {
  text-decoration: line-through;
  font-weight: 400;
}

@media screen and (max-width: 768px) {
  .bestseller-carousel__items {
    justify-content: flex-start;
    gap: 0.7rem;
  }
  .bestseller-carousel__item {
    width: 47%;
  }
  .bestseller-carousel__title {
    font-size: 13px;
  }
}
