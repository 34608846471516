.carousel {
  position: relative;
}

.carousel__items {
  display: flex;
  overflow-x: hidden;
  justify-content: center;
}

.carousel__item {
  flex: 0 0 auto;
  max-width: 500px;
  text-align: center;
  width: 100%;
}

.carousel__item img {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 10px;
  max-width: 200px;
}

.carousel__text {
  font-size: 16px;
  margin-bottom: 10px;
}

.carousel__button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.carousel__button:hover {
  background-color: #0056b3;
}

.carousel__nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 18px;
  cursor: pointer;
}

.carousel__nav--prev {
  left: 10px;
}

.carousel__nav--next {
  right: 10px;
}

.testimonial__img {
  max-width: 400px;
  margin: auto;
  text-align: center;
  width: 100%;
}

.testimonials__container {
  max-width: 750px;
  margin: auto;
  text-align: center;
}

.testimonials__card {
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem;
  border-radius: 1.5rem;
  margin-bottom: var(--mb-3);
  min-height: 8rem;
}

.testimonials__icon {
  font-size: 2rem;
  text-align: center;
  color: var(--title-color);
  width: 100%;
}

.testimonials__description {
  font-size: var(--small-font-size);
  text-align: center;
  margin-top: 0.5rem;
}
