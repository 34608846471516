.about {
  margin-top: 4rem;
}
.about__container {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  align-items: center;
  max-width: 1024px;
  gap: 1rem;
  margin: auto;
}

.about__img {
  width: 550px;
  border-radius: 1.5rem;
  justify-self: center;
}

.section__subtitle {
  text-align: center;
  font-size: var(--small-font-size);
  margin-bottom: 1rem;
}

.about__icon {
  font-size: 1.5rem;
  color: var(--title-color);
  margin-bottom: var(--mb-0-5);
}

.about__title {
  font-size: var(--small-font-size);
  font-weight: var(--font-medium);
}

.about__subtitle {
  font-size: var(--tiny-font-size);
}

.about__data p {
  margin-bottom: 1rem;
  text-align: left;
}

/**==== Breakpoints ====**/
@media screen and (max-width: 992px) {
  .about__container {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
