.services__container {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  justify-content: center;
  column-gap: 1.8rem;
}

.services__content {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: var(--container-color);
  padding: 1.5rem;
  border-radius: 5px;
}
.services__content:hover {
  box-shadow: 0 20px 20px -10px rgba(0, 0, 0, 0.2);
}

.services__icon {
  display: block;
  font-size: 2rem;
  text-align: center;
  color: var(--title-color);
  margin-bottom: var(--mb-1);
}

.services__title {
  font-size: var(--h3-font-size);
  margin-bottom: var(--mb-1);
  font-weight: var(--font-medium);
  text-transform: capitalize;
}
.services__title-info {
  font-size: var(--small-font-size);
  margin-bottom: var(--mb-2);
  margin-top: 0.5rem;
}

.service__button {
  color: var(--title-color);
  font-size: var(--smaller-font-size);
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding: 1rem;
  top: -6px;
  background: #ccc;
  width: 100%;
  gap: 0.5rem;
}

.service__button-icon {
  font-size: 1rem;
  transition: 0.3s;
}

.service__button:hover .service__button-icon {
  transform: translate(00.25rem);
}

.services__modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: var(--z-modal);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.services__modal-content {
  width: 800px;
  max-height: 700px;
  position: relative;
  background-color: var(--container-color);
  padding: 2rem;
  border-radius: 1.5rem;
  overflow-y: scroll;
}

.services__modal-close {
  position: absolute;
  top: 0.7rem;
  right: 0.7rem;
  font-size: 1.2rem;
  color: var(--title-color);
  cursor: pointer;
}

.services__modal-title {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-1);
}

.services__modal-description {
  font-size: var(--small-font-size);
  text-align: left !important;
}

.services__modal-service {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  padding-bottom: 0.5rem;
}

.services__modal-icon {
  color: var(--title-color);
  font-size: 1.1rem;
}

.active-modal {
  opacity: 1;
  visibility: visible;
}

.services__modal-services {
  gap: 0.5rem;
  padding-top: 0.5rem;
}

.services__modal-items {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
  font-size: var(--small-font-size);
}

.pservices__container {
  grid-template-columns: repeat(6, 1fr);
  gap: 1.5rem;
}

.pservices__inner {
  position: relative;
}

.pservices__inner-content {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.76);
  top: 1rem;
  padding: 1rem;
  color: black;
}

.pservices__inner-content h4 {
  font-size: 18px;
  font-weight: 700;
}

@media screen and (max-width: 768px) {
  .pservices__container {
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
  }
  .services__modal-items {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .pservices__inner-content {
    padding: 0.5rem;
  }
  .pservices__inner-content h4 {
    font-size: 13px;
    font-weight: 700;
  }
  .service__button {
    padding: 0.5rem;
  }
  .service__button-icon {
    font-size: 11px;
  }
}
